import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "Gilroy-Private",
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "white",
        },
        h1: {
          letterSpacing: "-5px",
          lineHeight: 1,
        },
        h2: {
          letterSpacing: "-4px",
          lineHeight: 1,
          fontSize: 72
        },
        h3: {
          letterSpacing: "-3px",
          lineHeight: 1,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
          fontWeight: 600,
          width: "fit-content",
          boxShadow: "none",
          textTransform: "none"
        },
        sizeLarge: {
          padding: "12px 24px"
        },
        sizeMedium: {
          padding: "8px 16px"
        }
      }
    }
  },
  palette: {
    mode: "dark",
    orange: {
      main: "#FF740F",
      contrastText: "#ffffff"
    },
    white: {
      main: "#ffffff",
      constastText: "#000000"
    },
    softWhite: {
      main: "#ffffff20",
      contrastText: "#ffffff"
    }
  }
});

export default theme;