export const FAQ_ITEMS = [
  {
    title: "Can you work within a certain budget?",
    desc: `Yes, we work within any and all realistic budgets, and an honest sharing of ideas and goals helps us immensely when making recommendations and suggested edits to travel plans.`,
  }, {
    title: "Does it cost money to get a quote?",
    desc: `No, there is no fee for our vacation planning services as the travel vendors involved recognize our value and compensate us from the gross amount paid (what you would have paid even without our assistance). For those complicated international itineraries that require custom crafting and significant involvement of our international on-location partners, we do request an intent-to-travel deposit to begin the process. This deposit is applied to the reservations that are made on your behalf.`,
  }, {
    title: "Where can I find my itenerary once my trip is booked?",
    desc: `Your itinerary will be mailed to you along with all necessary details and instructions`,
  }, {
    title: "Do I need travel insurance?",
    desc: `Travel insurance is available and recommended to protect your travel investment. Many trips, such as tours and cruises, involve non-refundable funds paid months in advance. Travel insurance protects you from losing this investment. Perhaps even more important is the ‘during travel’ coverage and assistance that comes with the top-flight insurance companies we carry.    `,
  },
];
