import { Typography, useMediaQuery, useTheme, Box } from "@mui/material"
import { Stack } from "@mui/system"
import { FEATURES } from "../constants"
import { InView } from "react-intersection-observer";

export const TextView = ({ setCurrItemIdx }) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  return <Stack mt={12} spacing={{ xs: 16, md: 0 }}>{FEATURES.map(({ icon, title, desc, image }, idx) =>
    <InView
      key={idx}
      threshold={0.5}
      onChange={(inView) => (inView ? setCurrItemIdx(idx) : "")}
    >
      {({ ref, inView }) => {
        return <Stack spacing={{ xs: 4, md: 0 }} justifyContent="center" key={idx} height={{ xs: "auto", md: "100vh" }}>
          <Stack ref={ref} spacing={2}>
            <Box py={2} px={4} borderRadius={100} width="fit-content" bgcolor="white.main" color='black.main'>
              {icon}
            </Box>
            <Typography variant={md ? "h2" : "h3"} fontWeight={600}>{title}</Typography>
            <Typography variant="body1" fontWeight={500} color="text.secondary">{desc}</Typography>
          </Stack>
          {!md && <img src={image} style={{
            width: "100%",
            height: "auto"
          }} alt="" />}
        </Stack>
      }}
    </InView>
  )}</Stack>
}