import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import { Stack } from "@mui/system";
import { useState } from "react";
import { AccordionGroup } from "./AccordionGroup"

export const FAQ = () => {
  const [expanded, setExpanded] = useState("panel0");
  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  return <Box width="100%" py={12}>
    <Box
      width={{ xs: "90%", lg: "80%" }}
      m="auto"
    >
      <Box data-sal="fade-up">
        <Stack spacing={8}>
          <Typography variant={md ? "h2" : "h3"} textAlign={{ xs: "left", md: "center" }} fontWeight={600} m="auto" maxWidth={900}>
            FAQ: Let’s see what’s on your mind
          </Typography>
          <AccordionGroup expanded={expanded} handleChange={handleChange} />
        </Stack>
      </Box>
    </Box>
  </Box>
}