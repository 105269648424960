import { Box, Button, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { LOCATIONS } from "./constants";
import { useState } from "react";
import { StackedCarousel } from "react-stacked-carousel"
import "react-stacked-carousel/dist/index.css";

const Locations = () => {
  const [card, setCard] = useState(null);
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const onCardChange = ({ currentIndex }) => setCard(currentIndex);
  return <Box width="100%" py={12}>
    <Box width={{ xs: "90%", md: "80%" }} m="auto">
      <Stack spacing={1} maxWidth={600}>
        <Typography variant={md ? "h2" : "h3"} fontWeight={600}>
          Recommended popular locations
        </Typography>
        <Typography variant="body1" color="text.secondary">
          We've got amazing locations for you and your friends to explore
        </Typography>
      </Stack>
      <Grid container my={2} spacing={6} alignItems="center">
        <Grid item md={6}>
          <StackedCarousel
            autoRotate={true}
            onCardChange={onCardChange}
            containerClassName={"stacked-container"}
            cardClassName="card"
            rotationInterval={5000}
          >
            {LOCATIONS.map(({ img, }, idx) => (
              <img src={img} alt="" />
            ))}
          </StackedCarousel>
        </Grid>
        <Grid item md={6}>
          <Stack spacing={1}>
            <Typography variant="h3" fontWeight={600}>
              {LOCATIONS[card]?.title}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {LOCATIONS[card]?.desc}
            </Typography>
          </Stack>
          <Box mt={4}>
            <Button color="white" size="large" variant="contained">Book now</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  </Box>
}

export default Locations;