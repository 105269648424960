export const NAVBAR_LINKS = [
  {
    title: "about",
    path: "/coming-soon",
  },
  {
    title: "roadmap",
    path: "/coming-soon",
  },
  {
    title: "testimonials",
    path: "/coming-soon",
  },
  {
    title: "privacy",
    label: "new",
    path: "/coming-soon",
  },
  {
    title: "team",
    path: "/coming-soon",
  },
];
