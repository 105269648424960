import { Flag, Group, LocationOn } from "@mui/icons-material";

export const FEATURES = [
  {
    icon: <Group />,
    image: "/media/images/Features/1.png",
    title: "Travel with all your friends",
    desc: "Hang out with your friend circle and explore the universe"
  },
  {
    icon: < Flag />,
    image: "/media/images/Features/2.png",
    title: "Amazing experiences & memories",
    desc: "We've planned out some amazing itenaries based on your tastes"
  },
  {
    icon: <LocationOn />,
    image: "/media/images/Features/3.png",
    title: "See what’s happening around you & join in!",
    desc: "Like the snap chat map, but way more fun, see where your friends are hanging out and join in on the fun"
  },
]