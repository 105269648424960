import { Typography, Box, Stack, Button, useTheme, useMediaQuery, Grid } from "@mui/material"
import { StatBlock } from "../StatBlock"

export const Hero = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  return <Box width="100%"
    sx={{ background: "url(/media/images/hero-bg.png)", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
  >
    <Box
      width={{ xs: "90%", lg: "70%" }}
      m="auto" py={20} maxWidth={1400}>
      <Grid container alignItems="center" spacing={4} columns={{ xs: 6, md: 12 }}>
        <Grid item xs={6}>
          <div data-sal="fade-up">
            <Stack spacing={2} textAlign={md ? "start" : "center"} alignItems={md ? "start" : "center"}>
              <Typography variant={sm ? "h2" : "h3"} fontWeight={600} maxWidth={700}>
                Travel the most efficacious way
              </Typography>
              <Typography width={{ xs: "90%", sm: "80%" }} variant="body2" color="text.secondary" fontWeight={500}>
                We're on the advent of releasing an extremely user friendly app that will enable the Gen-Z to get the most affordable experiences
              </Typography>
              <Box py={4}>
                <Button color="orange" size="large" variant="contained">Download Now</Button>
              </Box>
            </Stack>
          </div>
        </Grid>
        <Grid item xs={6}>
          <img src="/media/images/hero-image.png" alt="" width="100%" />
        </Grid>
      </Grid>
    </Box>
    {/* <ImageView /> */}
    <StatBlock />
  </Box>
}