import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material"
import { Stack } from "@mui/system"

export const DLBanner = () => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  return <Box width="100%" bgcolor="#00000020" py={12}>
    <Box
      width={{ xs: "90%", lg: "80%" }}
      m="auto"
    >
      <Stack spacing={2} justifyContent="center" alignItems="center" textAlign="center" maxWidth={700} m="auto">
        <img src="/media/images/switch-to-jt.png" height={80} alt="" />
        <Typography variant='body1' fontWeight={500}>Make a switch today</Typography>
        <Typography variant={md ? "h2" : "h3"} fontWeight={600}>Switch to Travelite & never miss out!</Typography>
        <Typography variant='body1' fontWeight={500} color="text.secondary" maxWidth={600}>Making a switch has never been this rewarding,
          we’re thrilled to deliver the best</Typography>
        <Box py={4}>
          <Button color="white" size="large" variant="contained">Try the app now</Button>
        </Box>
      </Stack>
    </Box>
  </Box>
}