import { EmailOutlined, LinkedIn, Twitter } from "@mui/icons-material";
import { Instagram } from "iconsax-react"

export const FOOTER_LINKS = [
  {
    title: "For businesses",
    path: "/link",
  },
  {
    title: "Careers",
    path: "/link",
  },
  {
    title: "FAQ",
    path: "/link",
  },
  {
    title: "About us",
    path: "/link",
  },
];

export const SOCIAL_LINKS = [
  {
    platform: "linkedIn",
    icon: <LinkedIn />,
    url: "https://linkedin.com",
  },
  {
    platform: "instagram",
    icon: <Instagram />,
    url: "https://instagram.com",
  },
  {
    platform: "Twitter",
    icon: <Twitter />,
    url: "https://twitter.com",
  },
  {
    platform: "Email",
    icon: <EmailOutlined />,
    url: "https://gmail.com",
  },
];
