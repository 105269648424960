import { CloseOutlined, Menu } from "@mui/icons-material";
import { Button, IconButton, Stack, useMediaQuery, useTheme } from "@mui/material"
import { Box } from "@mui/system"
import { NavlinkGroup } from "./NavlinkGroup"

export const Navbar = ({ isMenuVisible,
  userHasScrolled,
  setIsMenuVisible, }) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  return <Box width="90%"
    boxSizing="border-box"
    borderRadius={100}
    py={1.5} pr={1.5} pl={4}
    maxWidth={1400}
    m="auto" sx={{
      position: "fixed",
      top: "24px",
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 99,
      bgcolor: "transparent",
      boxShadow: userHasScrolled && "0 0 50px rgb(0, 0, 0, 0.2)",
      "@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))":
      {
        backgroundColor: userHasScrolled && "rgb(41, 45, 50, 0.9)",
        backdropFilter: userHasScrolled && "blur(4em)",
      },
    }}>
    <Box
      mx="auto"
    >
      <Stack justifyContent="space-between" alignItems="center" direction="row">
        <img src="/logos/logo+wordmark.svg" height={42} alt="alto" />
        {md && <NavlinkGroup />}
        {md && <Button color={userHasScrolled ? "white" : "softWhite"} size="medium" variant="contained">Get the app</Button>}
        {md || (
          <IconButton sx={{ color: "white.main" }} onClick={() => setIsMenuVisible(!isMenuVisible)}>
            {isMenuVisible ? <CloseOutlined /> : <Menu />}
          </IconButton>
        )}
      </Stack>
    </Box >
  </Box>
}