export const LOCATIONS = [
  {
    title: "Baga, Goa",
    img: "/media/images/Locations/baga.png",
    desc: `One of the most happening beaches in Goa, Baga Beach is where you will find water sports, fine dining restaurants, bars, and clubs. Situated in North Goa, Baga Beach is bordered by Calangute and Anjuna Beaches. Watersports are the major attraction of Baga Beach. Parasailing, wakeboarding, windsurfing, kite surfing, jet skiing are some of the top watersports activities to enjoy in Baga Beach. One can also go for dolphin spotting tours and island trips from the beach.`
  },
  {
    title: "Vagator, Goa",
    img: "/media/images/Locations/vagator.png",
    desc: `Known for its striking landscape that includes pristine beaches along with red cliffs in the backdrop, Vagator is indeed the most picturesque town in Goa that has a calming effect on the soul. Besides its natural beauty, this idyllic destination is steeped in rave culture and is popularly known as a hippie haven. Also emerging as the most exotic Goan locale, Vagator is where usually western backpackers are in larger numbers than any other place in Goa. Dotted with sea-view bars and pubs from where you can grab the most amazing views of sunset, swanky and ultra luxurious resorts for lodging, chic cafes and fine-dine restaurants to treat your palette, water sports activities and whatnot, this impressive neighborhood in North Goa is a heady mix of classy experiences.`
  },
  {
    title: "Dudhsagar, Goa",
    img: "/media/images/Locations/dudhsagar.png",
    desc: `Nestled between Goa and Karnataka border is the majestic Dudhsagar Waterfalls, which when literally translated means ‘sea of milk’ in Konkan.  Located in Sanguem Taluka, near Bhagwan Mahaveer Wildlife Sanctuary, Mollem, the waterfall originates from Karnataka flowing from the Deccan Plateau into the river Mandovi towards the imposing Sahyadri Western Ghats cascading from a height of 300 meters and splitting into three streams, forming a deep green pool with clouds of milky foam rising up at the base before continuing its journey to join the Arabian Sea in the west. The magnificent waterfall is an enchanting spectacle to behold as it vehemently gushes down appearing as a heavy stream of milk flowing effortlessly from a distance.`
  },
]