import { Box, Grid, Stack, useMediaQuery, useTheme } from "@mui/material"
import { useState } from "react"
import { ImageView } from "./ImageView"
import { TextView } from "./TextView";
import { FEATURES } from "./constants";

export const Features = () => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [currItemIdx, setCurrItemIdx] = useState(0);
  return <Box width="100%" pb={4} bgcolor="#23262A">
    <Box
      width={{ xs: "90%", lg: "80%" }}
      m="auto"
    >
      <Grid container spacing={6} columns={{ xs: 6, md: 12 }}>
        {md && <Grid item xs={0} md={1}>
          <Stack spacing={2} alignItems="center" justifyContent="center" borderRadius={100} sx={{
            position: "sticky",
            top: "32px",
            height: "100vh",
            zIndex: 9,
          }}>
            {FEATURES.map((elem, idx) =>
              <Box width={10} height={10} bgcolor={currItemIdx === idx ? "white.main" : "grey.700"} />
            )}
          </Stack>
        </Grid>}
        <Grid item xs={6} md={6}>
          <TextView setCurrItemIdx={setCurrItemIdx} />
        </Grid>
        <Grid item xs={6} md={5}>
          {md && <ImageView currItemIdx={currItemIdx} />}
        </Grid>
      </Grid>
    </Box>
  </Box>
}